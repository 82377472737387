import React, { useContext } from 'react';
import '../index.css';
import { NavContext } from '../context/NavContext';

const MobileMap = ({ images, fontColor }) => {
  const { activeLinkId } = useContext(NavContext);
  
  const handleClickNav = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <div
      id='pageMap'
      className={`z-50 flex items-baseline bottom-12 md:bottom-4 w-full justify-between bg-center bg-no-repeat pb-4 scale-90 sticky ${activeLinkId === 'client' ? 'invert transition ease-out duration-300 delay-75' : ""}`}
    >

        <div
          className={`flex h-auto flex-col items-center cursor-pointer scale-90 first-letter:transition ease-in-out delay-50 ${activeLinkId === 'home' ? 'invert scale-110' : ""}`}
          onClick={() => handleClickNav('home')}
        >
          <p
            className={`text-[0.65rem] pb-1 text-center mt-2 font-title ${fontColor.streetsign} transition ease-in-out delay-75 group-hover:scale-110 group-hover:-translate-y-2 duration-300`}
          >
            Start Here!
          </p>
          <img
            src={images.streetsign}
            alt='streetsign'
            className={`transition ease-in-out delay-50 group-hover:scale-110 group-hover:-translate-y-2 duration-300 w-20`}
          />
        </div>

      
        <div
          className={`flex flex-col items-center cursor-pointer scale-90 transition ease-in-out delay-50 ${activeLinkId === 'aboutUs' || activeLinkId === 'aboutUs2'  ? 'invert scale-110' : ""}`}
          onClick={() => handleClickNav('aboutUs')}
        >
          <p
            className={`text-[0.65rem] pb-1 text-center mt-2 font-title ${fontColor.streetsign} transition ease-in-out delay-75 group-hover:scale-110 group-hover:-translate-y-2 duration-300`}
          >
            The Wanderers
          </p> 
          <img src={images.bonfire} alt='bonfire' className='transition ease-in-out delay-50 group-hover:scale-110 group-hover:-translate-y-2 duration-300 w-20' />
        </div>

      
        <div
          className={`flex flex-col items-center cursor-pointer scale-90 transition ease-in-out delay-50 ${activeLinkId === 'services' ? 'scale-110' : ""}`}
          onClick={() => handleClickNav('services')}
        >
          <p
            className={`text-[0.65rem] pb-1 text-center mt-2 font-title ${activeLinkId === 'services' ? fontColor.backpackActive : fontColor.backpack} transition ease-in-out delay-75 group-hover:scale-110 group-hover: duration-300`}
          >
            Our Services
          </p> 
          <img src={`${activeLinkId === 'services' ? images.backpackActive : images.backpack}`} alt='backpack' className='transition ease-in-out delay-50 group-hover:scale-110 group-hover: duration-300 w-20' />       
        </div>

        <div
          className={`flex flex-col items-center cursor-pointer scale-90 transition ease-in-out delay-50 ${activeLinkId === 'client' ? 'invert scale-110' : ""}`}
          onClick={() => handleClickNav('client')}
        >
          <p
            className={`text-[0.65rem] pb-1 text-center mt-2 font-title ${activeLinkId === 'client' ? 'text-red-400' : fontColor.streetsign} transition ease-in-out delay-75 group-hover:scale-110 group-hover:duration-300`}
          >
            Our Clients
          </p>
          <img src={`${activeLinkId === 'client' ? images.tentActive : images.tent}`} alt='tent' className='transition ease-in-out delay-50 group-hover:scale-110 group-hover:duration-300 w-20' />
        </div>

      
        <div
          className={`flex flex-col items-center cursor-pointer scale-90 transition ease-in-out delay-50 ${activeLinkId === 'contact' ? 'scale-110' : ""}`}
          onClick={() => handleClickNav('contact')}
        >
          <p
            className={`text-[0.65rem] pb-1 text-center mt-2 font-title ${activeLinkId === 'contact' ? fontColor.compassActive : fontColor.compass} transition ease-in-out delay-75 group-hover:scale-110 group-hover: duration-300`}
          >
            Contact Us
          </p>
          <img src={`${activeLinkId === 'contact' ? images.compassActive : images.compass}`} alt='compass' className='transition ease-in-out delay-50 group-hover:scale-110 group-hover:-translate-y-2 duration-300 w-20' />
        </div>
    </div>
  );
};

export default MobileMap;
