import React from 'react';
import Window from '../components/window';
import bg5 from '../assets/bg5.PNG';
import mail from '../assets/mail.png';
import ping from '../assets/ping.png';
import fb from '../assets/fb.png';
import ig from '../assets/ig.png';
import emailjs from '@emailjs/browser';
import { useNav } from '../hooks/useNav';

const ContactUs = () => {
  const contactRef = useNav('contact');
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_3ujp5dg', 'template_2mxcqbb', e.target, 'jB7oZ1yYxZ2oK_DF3')
      .then((result) => {
          console.log(result.text);
          window.location.reload();
      }, (error) => {
          console.log(error.text);
      });
  };
    
    return (
      <section ref={contactRef} className='relative w-full h-screen flex flex-col' id='contact'> 
          <img src={bg5} className="absolute inset-0 w-full h-screen object-cover" alt='bg5' />  
        <div className='relative w-full h-screen bg-contact bg-opacity-75 flex flex-col justify-start'>
          <div className='grid grid-cols-12 gap-2 mt-36 md:gap-4 mx-6 lg:mx-12 xl:mx-24 lg:mt-24 xl:mt-20'>
              <div className='col-span-full flex justify-center'>
                <h1 className='font-paragraph font-bold text-2xl lg:text-4xl xl:text-5xl text-about' >Journey with Us!</h1>
              </div>
              <div className='col-span-full flex justify-center'>
                <h1 className='font-title text-xl lg:text-2xl xl:text-3xl text-white text-center'>Get a FREE brand discovery from us!</h1>
              </div>
              <div className='col-start-1 col-span-12 mb-8 lg:mb-0 lg:col-start-3 lg:col-span-8 xl:col-start-4 xl:col-span-6 flex'>
                  <form 
                    className=' z-50 col-span-4 w-full flex flex-col items-center lg:mx-6 '
                    onSubmit={sendEmail}
                    >
                    <input type="text" name="name" className='p-1 md:p-2 m-2 bg-transparent w-11/12 md:w-9/12 border-4 rounded-xl border-white text-white' placeholder='Name' /> 
                    <input type="email" name="email" className='p-1 md:p-2 m-2 bg-transparent w-11/12 md:w-9/12 border-4 rounded-xl border-white text-white' placeholder='Email' />
                    <input type="text" name="number" className='p-1 md:p-2 m-2 bg-transparent w-11/12 md:w-9/12 border-4 rounded-xl border-white text-white' placeholder='Phone Number' />
                    <button className='bg-about px-6 py-2 mt-4 rounded-lg font-bold font-paragraph text-xs md:text-base transition cursor-pointer z-50 hover:invert'>Start Adventure!</button>
                  </form>
              </div>
              <div className='col-start-10 col-span-2 mt-0 hidden lg:flex justify-center items-center'>
                  <Window text='SCROLL UP' paragraph='To revisit the map!'/>
                </div>    
              <div className='scale-75 xl:scale-100 col-start-1 col-span-full md:col-span-10 flex flex-col lg:relative lg:bottom-4 xl:bottom-0'>
                <div className='flex md:pb-2'>
                  <img src={mail} alt="mail" className='scale-75'/>
                  <p className='text-white text-md px-4 font-paragraph'>admin@kreativnomad.com</p>
                </div>
                <div className='flex md:pb-2 pl-1'>
                  <img src={ping} alt="ping" className='object-contain scale-75' />
                  <p className='text-white text-md pt-2 px-5 leading-tight font-paragraph'>2nd floor 290 Aguirre Ave, Phase 3, BF Homes, Parañaque City</p>
                </div>
              </div>
              <div className='md:col-start-11 col-start-1 gap-2 col-span-full flex justify-center md:justify-end items-end md:-translate-y-5'>
                <img src={fb} alt='fb' className='relative w-8 cursor-pointer object-contain z-50' 
                    onClick={() => window.location.href='https://www.facebook.com/kreativnomad'} />
                <img src={ig} alt='ig' className='relative w-8 cursor-pointer object-contain z-50' 
                    onClick={() => window.location.href='https://www.instagram.com/kreativnomad/'}/>
              </div>
          </div>       
        </div>        
      </section>
  )
}

export default ContactUs