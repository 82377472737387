import React, { useState, useEffect } from 'react';
import './index.css';
import HomePage from './scenes/homePage';
import AboutUs from './scenes/aboutUs';
import AboutUs2 from './scenes/aboutUs2';
import Services from './scenes/services';
import ClientPage from './scenes/clientPage';
import ContactUs from './scenes/contactUs';
import NavBar from './components/navBar';
import PageMap from './components/pageMap';
import axes from './assets/axes.png';
import backpack from './assets/backpack.png';
import bonfire from './assets/bonfire.png';
import compass from './assets/compass.png';
import streetsign from './assets/streetsign.png';
import tent from './assets/tent.png';
import tentActive from './assets/client-active.png';
import backpackActive from './assets/services-active.png';
import compassActive from './assets/contact-active.png';
import trail from './assets/trail.png';
import NavProvider from './context/NavContext';
import MobileMap from './components/MobileMap';
import { useMediaQuery } from 'react-responsive';
import Preloader from './components/Preloader';

function App() {
  const [preloader, setPreloader] = useState(true);
  const images = {
    streetsign: streetsign,
    axes: axes,
    backpack: backpack,
    bonfire: bonfire,
    compass: compass,
    tent: tent,
    tentActive: tentActive,
    backpackActive: backpackActive,
    compassActive: compassActive,
    trail: trail,
  };

  const colors = {
    streetsign: 'text-white',
    axes: 'text-white',
    backpack: 'text-white',
    bonfire: 'text-white',
    compass: 'text-white',
    tent: 'text-white',
    tentActive: 'text-crafts',
    backpackActive: 'text-beige',
    compassActive: 'text-beige',
    trail: 'text-white',
  };

  const isLargeScreen = useMediaQuery({ minWidth: 1024 });
  const clear = () => {
    setPreloader(false);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      clear();
    }, 5000);

    return () => clearTimeout(id);
  }, []);
  
  return (
    <NavProvider>

      { preloader ? <Preloader /> : (
      <div className='no-scrollbar snap-mandatory snap-y overscroll-y-contain overflow-y-auto h-screen w-screen'>
          <div className='snap-start' id='section'>
            <HomePage />
          </div>
          <div className='snap-start' id='section'>
            <AboutUs />
          </div>
          <div className='snap-start' id='section'>
            <AboutUs2 />
          </div>
          <div className='snap-start' id='section'>
            <Services />
          </div>
          <div className='snap-start' id='section'>
            <ClientPage />
          </div> 
          <div className='snap-start' id='section'>
            <ContactUs />
          </div>
          <nav>
            <NavBar />
          </nav>
          {isLargeScreen ? (
          <PageMap images={images} fontColor={colors} />
        ) : (
          <MobileMap images={images} fontColor={colors} />
        )}           
        </div> 
    )}
    </NavProvider>
  );
}

export default App;
