import React from 'react';
import Window2 from '../components/window2'; 
import background2 from '../assets/aboutUs2-bg.PNG';
import { useNav } from '../hooks/useNav';
import image28 from '../assets/WEBSITE-28.png';
import image29 from '../assets/WEBSITE-29.png';
import image30 from '../assets/WEBSITE-30.png';

const AboutUs2 = () => {
  
  const nextRef = useNav("aboutUs2");

  return (
    <>
    <section className='relatives w-screen h-screen bg-about bg-cover bg-no-repeat flex items-center xl:items-start' style={{backgroundImage: `url(${background2})`}} ref={nextRef} id='aboutUs2'> 
      <div className='grid grid-cols-12 gap-4 w-full py-8 mx-4 md:mx-12 mb-20 lg:mb-0 '>
            <div className='col-start-10 col-span-2 mt-10 hidden lg:flex justify-end'>
                <Window2 text='SCROLL DOWN.' paragraph='For the next stop!' />
            </div>
            <div className='flex flex-col md:flex-row col-start-1 scale-90  xl:scale-100 col-span-12 md:items-baseline'>
              <div className='flex flex-col items-center md:flex-1 '>
                  <img src={image28} alt='image28' className='w-16 md:w-32'></img>
                  <h1 className='font-paragraph text-xl lg:text-4xl md:pb-2 text-center leading-snug'><strong>DISCOVER</strong></h1>
                  <h1 className='font-paragraph md:mx-8 text-lg lg:text-3xl text-center md:pb-6 '>We hone digital marketing and provide visionary strategies.</h1>
              </div>
              <div className='flex flex-col items-center md:flex-1 '>
                  <img src={image29} alt='image29' className='w-16 md:w-32'></img>
                  <h1 className='font-paragraph text-xl lg:text-4xl md:pb-2 text-center leading-snug'><strong>INNOVATE</strong></h1>
                  <h1 className='font-paragraph md:mx-8  text-lg lg:text-3xl text-center md:pb-6'>Creativity is Infinite and so are we.</h1>
              </div>
              <div className='flex flex-col items-center md:flex-1 '>
                  <img src={image30} alt='image30' className='w-16 md:w-32'></img>
                  <h1 className='font-paragraph text-xl lg:text-4xl md:pb-2 text-center leading-snug'><strong>EMPOWER</strong></h1>
                  <h1 className='font-paragraph md:mx-8  text-lg lg:text-3xl text-center md:pb-6'>Our course of action help brands get traction online.</h1>
              </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs2;