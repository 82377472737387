import logo from '../assets/logo.png';
import React, { useContext, useState, useEffect, useRef } from 'react';
import logo2 from '../assets/logo4.png';
import { NavContext } from '../context/NavContext';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const NavBar = () => {
  const { activeLinkId } = useContext(NavContext);
  const [open, setOpen] = useState(false);
  const imgRef = useRef(null);
  const buttonRef = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  const handleClick = () => {
    setOpen(!open);
  }

  const handleClickNav = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setTimeout(() => {
      setOpen(false);
    }, 200);
  }

  useEffect(() => {
    const handleClickAnywhere = (event) => {

      if (activeLinkId === 'contact') {
        return; // Ignore the click event if the active page is the "Contact Us" page
      }
      

      if (!open) {
        const minX = 70; // Minimum X coordinate
        const maxX = 1500; // Maximum X coordinate
        const minY = 150; // Minimum Y coordinate
        const maxY = 550; // Maximum Y coordinate

        const { clientX, clientY} = event;

        // Check if the click event is within the desired area
        if (clientX >= minX && clientX <= maxX && clientY >= minY && clientY <= maxY) {
          const pageMap = ['home', 'aboutUs', 'services', 'client', 'contact'];
          const currentIndex = pageMap.indexOf(activeLinkId);
          const nextPage = pageMap[(currentIndex + 1) % pageMap.length];

          const element = document.getElementById(nextPage);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    };

    window.addEventListener('click', handleClickAnywhere);

    return () => {
      window.removeEventListener('click', handleClickAnywhere);
    };
  }, [activeLinkId, open]);
  
  useEffect(() => {
    const imgElement = imgRef.current;
    const buttonElement = buttonRef.current;
    gsap.set ([imgElement, buttonElement], {y: -20})
    gsap.to(imgElement, { opacity: 1, duration: 1, y: 0, delay: 3});
    gsap.to(buttonElement, { opacity: 1, duration: 1, y: 0, delay: 3});

  }, []);
  return (
    <nav className="z-50 flex bg-transparent place-content-between top-0 left-0 w-full px-8 pb-0 pt-6 fixed lg:px-12 xl:px-24 ">
      <img
        onClick={() => handleClickNav('home')}
        src={`${activeLinkId === 'client' ? logo2 : logo}`}
        alt="logo"
        className={`w-20 z-50 lg:w-28 scale-90 duration-300 cursor-pointer opacity-0`}
        ref = {imgRef}
      />
      <button
        className={`z-50 w-10 h-12 relative bg-transparent opacity-0 ${activeLinkId === 'client' ? 'text-yellow-400' : 'text-white'}`}
        onClick={handleClick}
        ref={buttonRef}
      >
        <span className="sr-only">Open main menu</span>
        <div className="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <span
            aria-hidden="true"
            className={`block absolute h-1 w-6 rounded-full bg-current transform transition duration-500 ease-in-out ${
              open ? 'rotate-45' : '-translate-y-1.5'
            }`}
          ></span>
          <span
            aria-hidden="true"
            className={`block absolute h-1 w-6 bg-current rounded-full transform transition duration-500 ease-in-out ${
              open ? '-rotate-45' : 'translate-y-1.5'
            }`}
          ></span>
        </div>
      </button>
        

  <div className={`opacity-0 fixed transition-opacity duration-300 backdrop-blur-md ${open ? 'opacity-100 flex justify-center items-center inset-0 z-0'  : ''}`}>
      {open && (
          <div className="delay-150">
              <div className="block">
                <li
                  className={`${activeLinkId === 'client' ? 'text-yellow-400' : 'text-white'} list-none text-center font-title text-2xl p-1 cursor-pointer transition-opacity duration-300 hover:opacity-75`}
                  onClick={() => handleClickNav('home')}
                >
                  Home
                </li>
                <li className={`${activeLinkId === 'client' ? 'text-yellow-400' : 'text-white'} list-none  text-center font-title text-2xl p-1 cursor-pointer transition-opacity duration-300 hover:opacity-75`} onClick={() => handleClickNav('aboutUs')}>About Us</li>
                <li className={`${activeLinkId === 'client' ? 'text-yellow-400' : 'text-white'} list-none  text-center font-title text-2xl p-1 cursor-pointer transition-opacity duration-300 hover:opacity-75`} onClick={() => handleClickNav('services')}>Our Services</li>
                <li className={`${activeLinkId === 'client' ? 'text-yellow-400' : 'text-white'} list-none  text-center font-title text-2xl p-1 cursor-pointer transition-opacity duration-300 hover:opacity-75`} onClick={() => handleClickNav('client')}>Our Clients</li>
                <li className={`${activeLinkId === 'client' ? 'text-yellow-400' : 'text-white'} list-none  text-center font-title text-2xl p-1 cursor-pointer transition-opacity duration-300 hover:opacity-75`} onClick={() => handleClickNav('contact')}>Contact</li>
              </div>
          </div>
      )}
      </div>
    </nav>
  )
}

export default NavBar;
