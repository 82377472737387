import React from 'react';
import backgroundServices from '../assets/services-bg.PNG';
import backgroundDecor from '../assets/bg-decor-removebg.png'
import { useNav} from '../hooks/useNav';
import windowTemplate from '../assets/window.png';
import image31 from '../assets/WEBSITE-31.png';
import image32 from '../assets/WEBSITE-32.png';
import image33 from '../assets/WEBSITE-33.png';
import image34 from '../assets/WEBSITE-34.png';


const Services = () => {

  const serviceRef = useNav("services");

  return (
    <section className='relative w-full h-screen flex flex-col items-center' id='services' ref={serviceRef}> 
        <img src={backgroundServices} className="absolute w-full h-screen object-cover" alt='bg-services' />  
      <div className='relative w-full h-screen bg-services bg-opacity-75 flex flex-col '>
        <img src={backgroundDecor} alt="bg-decor" className='absolute w-full'  />
        <div className='flex w-full scale-75 xl:scale-100 items-start'>
          <div className='grid grid-cols-12 gap-4 w-full mx-4 md:mx-24 lg: mt-32 '>
              <div className='absolute right-24 top-16 col-start-10 col-span-3 mt-10 hidden xl:flex justify-end items-center'>
                <div className={`relative hidden xl:inline-block`}>
                  <img src={windowTemplate} alt="window" className="w-full" style={{boxShadow: '10px 13px 35px -20px rgba(0,0,0,0.49)'}}/>
                  <div className="w-full max-h-16 absolute top-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
                    <h1 className='font-paragraph text-[10px] '><strong>CLICK ANYWHERE ON THE SCREEN.</strong></h1>
                    <p className='text-[10px]'>To locate some of our crafts</p>
                  </div>
                </div>
              </div>
              <div className='col-start-1 col-span-12 flex items-baseline '>
                  <div className='flex flex-col items-center justify-center w-1/2 scale-90'>
                    <img src={image31} alt="image31" className='w-24 mb-4' />
                    <h1 className='font-paragraph text-xl md:text-3xl text-beige text-bold'><strong>Brand Strategy</strong></h1>
                    <p className='text-white text-center text-lg md:text-2xl'>(Communication and Visual Guidelines)</p>
                  </div>
                  <div className='flex flex-col items-center justify-center w-1/2 '>
                    <img src={image32} alt="image32" className='w-24 mb-4' />
                    <h1 className='font-paragraph text-center text-xl md:text-3xl text-beige text-bold'><strong>Website Design and Development</strong></h1>
                  </div>

              </div>
              <div className='col-start-1 col-span-12 flex items-baseline'>
                  <div className='flex flex-col items-center justify-center w-1/2'>
                    <img src={image33} alt="image33" className='w-24 mb-4' />
                    <h1 className='font-paragraph text-center text-xl md:text-3xl text-beige text-bold'><strong>Social Media Marketing</strong></h1>
                  </div>
                  <div className='flex flex-col items-center justify-center w-1/2'>
                    <img src={image34} alt="image34" className='w-24 mb-4' />
                    <h1 className='font-paragraph text-xl md:text-3xl text-beige text-bold'><strong>Creative Services</strong></h1>
                    <p className='text-white text-center text-lg md:text-2xl'>(Photoshoot, brand kit, marketing collaterals)</p>
                  </div>  
              </div>
              
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services;