import React, { useEffect, useRef, useContext } from 'react';
import { gsap } from 'gsap';
import trail from '../assets/trail.png';
import '../index.css';
import { NavContext } from '../context/NavContext';

const PageMap = ({ images, fontColor }) => {
  const { activeLinkId } = useContext(NavContext);
  const tl= gsap.timeline();
  const pageMapRef = useRef(null);

  const handleClickNav = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const pageMapElement = pageMapRef.current;

    tl.to([pageMapElement], {
      opacity: 1,
      duration: 1,
      delay:  3.2,
      y: -20
    })
  });

  return (
    <>
      <div
        id='pageMap'
        ref={pageMapRef}
        className={`z-50 flex -bottom-4 w-full justify-around bg-center bg-no-repeat fixed opacity-0 ${activeLinkId === 'client' ? 'invert transition ease-out duration-300 delay-75' : ""}`}
        style={{ backgroundImage: `url(${trail})`,
                  backgroundSize: `95% 220%`}}
      >

          <div
            className={`group relative bottom-14 flex flex-col items-center cursor-pointer transition ease-in-out delay-75 ${activeLinkId === 'home' ? 'invert scale-110 translate-y-2' : ""}`}
            onClick={() => handleClickNav('home')}
          >
            <img
              src={images.streetsign}
              alt='streetsign'
              className={`transition ease-in-out delay-75 group-hover:scale-110 group-hover:-translate-y-2 duration-300 w-20`}
            />
            <p
              className={`text-lg mt-2 font-title ${fontColor.streetsign} transition ease-in-out delay-75 group-hover:scale-110 group-hover:-translate-y-2 duration-300`}
            >
              Start Here!
            </p>
          </div>

        
          <div
            className={`group relative bottom-8 flex flex-col items-center cursor-pointer transition ease-in-out delay-75 ${activeLinkId === 'aboutUs' || activeLinkId === 'aboutUs2'  ? 'invert scale-110 translate-y-2' : ""}`}
            onClick={() => handleClickNav('aboutUs')}
          >
            <img src={images.bonfire} alt='bonfire' className='transition ease-in-out delay-75 group-hover:scale-110 group-hover:-translate-y-2 duration-300 w-20'/>
            <p className={`text-lg mt-2 font-title ${fontColor.bonfire} transition ease-in-out delay-75 group-hover:scale-110 group-hover:-translate-y-2 duration-300`}>The Wanderers</p>
          </div>

        
          <div
            className={`group relative bottom-14 flex flex-col items-center cursor-pointer transition ease-in-out delay-75 ${activeLinkId === 'services' ? 'scale-110 translate-y-2' : ""}`}
            onClick={() => handleClickNav('services')}
          >
            <img src={`${activeLinkId === 'services' ? images.backpackActive : images.backpack}`} alt='backpack' className='transition ease-in-out delay-75 group-hover:scale-110 group-hover:-translate-y-2 duration-300 w-20' />
            <p className={`text-lg mt-2 font-title ${activeLinkId === 'services' ? fontColor.backpackActive : fontColor.backpack} transition ease-in-out delay-75 group-hover:scale-110 group-hover:-translate-y-2 duration-300`}>Our Services</p>
          </div>
        
          <div
            className={`group relative bottom-8 flex flex-col items-center cursor-pointer transition ease-in-out delay-75 ${activeLinkId === 'client' ? 'invert scale-110 translate-y-2' : ""}`}
            onClick={() => handleClickNav('client')}
          >
            <img src={`${activeLinkId === 'client' ? images.tentActive : images.tent}`} alt='tent' className='transition ease-in-out delay-75 group-hover:scale-110 group-hover:-translate-y-2 duration-300 w-20'/>
            <p className={`text-lg mt-2 font-title ${activeLinkId === 'client' ? fontColor.tentActive : fontColor.tent} transition ease-in-out delay-75 group-hover:scale-110 group-hover:-translate-y-2 duration-300`}>Our Clients</p>
          </div>

        
          <div
            className={`group relative bottom-14 flex flex-col items-center cursor-pointer transition ease-in-out delay-75 ${activeLinkId === 'contact' ? 'scale-110 translate-y-2' : ""}`}
            onClick={() => handleClickNav('contact')}
          >
            <img src={`${activeLinkId === 'contact' ? images.compassActive : images.compass}`} alt='compass' className='transition ease-in-out delay-75 group-hover:scale-110 group-hover:-translate-y-2 duration-300 w-20'/>
            <p className={`text-lg mt-2 font-title ${activeLinkId === 'contact' ? fontColor.compassActive : fontColor.compass} transition ease-in-out delay-75 group-hover:scale-110 group-hover:-translate-y-2 duration-300`}>Contact Us</p>
          </div>
      </div>
    </>
  );
};

export default PageMap;
