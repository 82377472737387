import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import logo4 from '../assets/logo4.png'

const Preloader = () => {
  const logoRef = useRef(null);
  const spinnerRef = useRef(null);
  const bgRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline();
    const logoElement = logoRef.current;
    const spinnerElement = spinnerRef.current;
    const bgElement = bgRef.current;
    
    // Animate out the logo and spinner
    tl.to(logoElement, { opacity: 0, duration: 0.5, delay: 3.5 });
    tl.to(spinnerElement, { opacity: 0, duration: 0.5}, '-=1');
    tl.to(bgElement, { backgroundColor: '#D7A53E', duration: 1});

    // Optional: Add additional cleanup code if needed
    return () => {
      // Reset the opacity back to 1 for the logo and spinner
      gsap.set(logoElement, { opacity: 1 });
      gsap.set(spinnerElement, { opacity: 1 });
    };
  }, []);

  return (
    <div className='flex flex-col justify-center items-center bg-almost-black h-screen w-full absolute ' ref={bgRef}>
        <img src={logo4} alt="logo4" className='w-28' ref={logoRef} />
        <div className="spinner" ref={spinnerRef}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
  )
}

export default Preloader