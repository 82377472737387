import React from 'react';
import Window2 from '../components/window2';
import { useNav } from '../hooks/useNav';
import image27 from '../assets/WEBSITE-27.png';


const AboutUs = () => {
  const aboutRef = useNav('aboutUs');

  return (
    <section ref={aboutRef} className='relative w-full h-screen flex items-center xl:items-start bg-about' id='aboutUs'>
      <div className='grid grid-cols-12 gap-2 mx-6 md:mx-24 xl:mt-4'>
        <div className='col-start-9 col-span-3 mt-10 hidden xl:flex justify-end'>
          <Window2 text='SCROLL DOWN.' paragraph='To get to know us more!' />
        </div>
        <div className='col-start-1 col-span-full md:hidden'>
          <img src={image27} alt='image27' loading='lazy' />
        </div>
        <div className='col-start-1 col-span-12 md:col-start-1 md:col-span-7 flex lg:mt-0 lg:ml-12'>
          <h1 className='font-paragraph text-xl md:text-3xl text-justify xl:text-4xl xl:leading-normal'>
            <strong>We are Kreativ Nomad, </strong>a group of creative wanderers with years of experience in digital marketing. In the last four years, we
            have helped micro businesses and SME's empower their brands by crafting their creative digital marketing solution.
          </h1>
        </div>
        <div className='hidden col-start-8 col-span-full md:flex object-contain '>
          <img src={image27} alt='image27' />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;