import React, { useContext, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import windowTemplate from '../assets/window.png';
import { NavContext } from '../context/NavContext';

const Window = ({text, paragraph}) => {
  const { activeLinkId } = useContext(NavContext);
  const windowRef = useRef(null);

  useEffect(() => {
    const windowElement = windowRef.current;
    // Animate out the logo and spinner
    gsap.to(windowElement, { opacity: 1, duration: 2, delay: 2});

  }, []);


  return (
    <div className={`${activeLinkId === 'client' ? 'invert hue-rotate-90 transition ease-in-out duration-300 delay-75' : "relative hidden xl:inline-block transition ease-in-out duration-300 delay-75 opacity-0"}`} 
      ref={windowRef} 
      style={{
        boxShadow: '10px 13px 35px -20px rgba(0,0,0,0.49)'
      }}
    >
      <img
        src={windowTemplate}
        alt="window"
        className="w-full"
      />
      <div className="w-full max-h-16 absolute top-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
        <h1 className='font-paragraph'><strong>{text}</strong></h1>
        <p>{paragraph}</p>
      </div>
    </div>
  )
}

export default Window