import React from 'react';
import windowTemplate from '../assets/window.png';

const window = ({text, paragraph}) => {
  return (
    <div className="relative z-50 hidden xl:inline-block ease-in-out"
    style={{
      boxShadow: '10px 13px 35px -20px rgba(0,0,0,0.49)'
    }}>
      <img src={windowTemplate} alt="window" className="w-full" />
      <div className="absolute w-full top-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-base text-center">
        <h1 className='font-paragraph'><strong>{text}</strong></h1>
        <p>{paragraph}</p>
      </div>
    </div>
  )
}

export default window