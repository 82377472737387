import React from 'react';
import { useNav } from '../hooks/useNav';
import Window from '../components/window';
import bg3 from '../assets/WEBSITE-07.jpg'
import logo10 from '../assets/brand logo-10.png';
import logo11 from '../assets/brand logo-11.png';
import logo12 from '../assets/brand logo-12.png';
import logo13 from '../assets/brand logo-13.png';
import logo14 from '../assets/brand logo-14.png';
import logo15 from '../assets/6.png';
import logo16 from '../assets/brand logo-16.png';
import logo20 from '../assets/brand logo-20.png';
import logo22 from '../assets/brand logo-22.png';


const ClientPage = () => {
  const clientRef = useNav("client");


  return (
    <>
    <section ref={clientRef} 
      className='relative w-full h-screen bg-white bg-cover bg-no-repeat flex mt-24 items-center -translate-y-10 lg:translate-y-0  lg:items-start' 
      style={{
        backgroundImage: `url(${bg3})`,
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat', 
      }} 
      id='client'
    > 
      <div className='grid grid-cols-12 mx-12 h-4/6 m:h-auto md:mx-24 lg:mt-10'>
        <div className='col-span-full flex justify-center mt-10 xl:mt-0 '>
          <h1 className='font-paragraph font-bold text-3xl text-center md:text-4xl xl:text-5xl'>Brands we've worked with!</h1>  
        </div>
        <div className='col-span-12 col-start-1 md:col-span-8 md:col-start-3'>
          <div className='grid grid-rows-2 grid-cols-3'>
              <div>
                <img src={logo10} alt="logo10" />
              </div>
              <div>
                <img src={logo12} alt="logo12" />
              </div>
              <div>
                <img src={logo14} alt="logo14"/>
              </div>
              <div>
                <img src={logo11} alt="logo10"/>
              </div>
              <div>
                <img src={logo13} alt="logo12" />
              </div>
              <div className='flex justify-center '>
                <img src={logo15} alt="logo14" className='w-16 md:w-28 h-auto object-contain' />
              </div>
          </div>
        </div>
        <div className="col-start-11 col-span-2 mt-0 hidden xl:flex justify-end items-end">
                <Window text='SCROLL DOWN.' paragraph="To contact us!"/>
        </div>
        <div className="col-span-12 col-start-1 md:col-span-4 md:col-start-5">
          <div className='flex items-center justify-evenly'>
              <div>
                <img src={logo16} className='object-contain w-12 md:w-24 ' alt="logo16" />
              </div>     
              <div>
                <img src={logo20} className='object-contain w-12  md:w-24' alt="logo20" />
              </div>
              <div>
                <img src={logo22} className='object-contain w-12  md:w-24' alt="logo22" />
              </div>
            </div>
        </div>

      </div>     
      </section>
    </>
  )
}

export default ClientPage