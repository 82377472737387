import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import videoBG from '../assets/bg-video.mp4';
import Window from '../components/window';
import { useNav } from '../hooks/useNav';

const HomePage = () => {
  const homeRef = useNav("home");
  const titleRef = useRef(null);
  const bgRef = useRef(null);
  const tl = gsap.timeline();

  useEffect(() => {
    const titleElement = titleRef.current;
    const bgElement = bgRef.current;
    
    gsap.set(titleElement, { y: 20 });

    // Animate out the logo and spinner
    tl.to(bgElement, { opacity: 0.75, duration: 3, delay: 1});
    tl.to(titleElement, { opacity: 1, duration: 1, y: 0 }, '-=1');

  });

  return (
    <>  
      <section className=" relative w-full h-screen flex items-center xl:items-start" id='home' ref={homeRef}> 
        <video autoPlay muted loop className="absolute right-0 bottom-0 min-w-full min-h-full object-cover z-10" loading='lazy'>
          <source src={videoBG} type="video/mp4" />
        </video>
        <div className='bg-background w-full h-screen absolute z-20' ref={bgRef}></div>
        <div className='grid grid-cols-12 gap-4 py-8 mx-4 w-full md:mx-24 lg:mt-20 z-30'>
          <div className='col-start-9 col-span-3 mt-10 hidden lg:flex justify-end'>
            <Window paragraph='Click to start you journey.'/>
          </div>
          <div className='col-start-1 col-span-12 my-10 lg:mb-36 flex justify-center z-40'>
            <h1 className='font-title text-white text-4xl text-center md:text-5xl opacity-0' ref={titleRef}>Your creative marketing journey starts here! </h1>       
          </div>
        </div>
      </section>
    </>
  )
}

export default HomePage;